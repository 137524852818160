// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
require('jquery')

window.addEventListener("load", function() {
  const swiper = new Swiper('.swiper', {
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    }
  })

});

// ハンバーガーメニュー
window.addEventListener("load", function() {
  function scroll_control(event) {
    event.preventDefault();
  }
  //変数の設定
  var $body = $('boby');

  //スクロール量を保存
  var scrollTop;

  //スクロールを固定
  function bodyFixedOn() {
    scrollTop = $(window).scrollTop();
    $body.css({
      position: 'fixed',
      top: -scrollTop
    });
  }

  //スクロールの固定を解除
  function bodyFixedOff() {
    $body.css({
      position: '',
      top: ''
    });

    $(window).scrollTop(scrollTop);
  }


  const ham = $('#menu_list');
  const nav = $('#js-nav');
  ham.on('click', function () {
    ham.toggleClass('active');
    nav.toggleClass('active');
    if ($(ham).hasClass('active')) {
      bodyFixedOn()
    } else {
      bodyFixedOff()
    }
  });
});


// $(function(){
//   var pagetop = $('#page_top');
//   pagetop.hide();
//   $(window).scroll(function () {
//     var top = $('.banner').offset().top;
//     var position = top - $(window).height();
//       if ($(this).scrollTop() > position) {
//         pagetop.fadeIn();
//       } else {
//         pagetop.fadeOut();
//       }
//   });
//   pagetop.click(function () {
//       $('body, html').animate({ scrollTop: 0 }, 500);
//       return false;
//   });
// });

$(document).ready(function() {
  $("a").click(function(e) {
    if ($(this).attr("target") === "_blank") {
      return;
    }
    e.preventDefault();
    $.LoadingOverlay("show");
    window.location.href = $(this).attr("href");
  });
});
